<template>
    <div class="matching">
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>项目匹配</el-breadcrumb-item>
        </el-breadcrumb>
        <router-view></router-view>
    </div>
</template>

<script>
export default {
  name: 'matching',
  data () {
    return {};
  }
};
</script>

<style lang="less" scoped>
  .matching{
      background: #fff;
      padding: 44px 0px;
      height: auto;
  }
</style>
